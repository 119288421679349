body {
  background-color: #1a1a1a;
  background-image: none;
  background-repeat: repeat;
  background-size: auto;
}

.circuit-board {
    background-color: #1a1a1a;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    background-size: auto;
    z-index: -1;
  }
  
  .node {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #00ff00;
  }

  @keyframes node-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .node:nth-child(1) {
    top: 30%;
    left: 7.5%;
  }
  
  .node:nth-child(2) {
    animation: node-animation 20s linear infinite;
    top: 50%;
    left: 20%;
  }
  
  .node:nth-child(3) {
    top: 70%;
    left: 40%;
  }
  
  .node:nth-child(4) {
    top: 40%;
    left: 70%;
  }
  
  .node:nth-child(5) {
    animation: node-animation 20s linear infinite;
    top: 61%;
    left: 80%;
  }

  .node:nth-child(6) {
    top: 82%;
    left: 15%;
  }
  
  .node:nth-child(7) {
    animation: node-animation 20s linear infinite;
    top: 20%;
    left: 89%;
  }
  
  .node:nth-child(8) {
    top: 90%;
    left: 85%;
  }
  
  .node:nth-child(9) {
    animation: node-animation 20s linear infinite;
    top: 90%;
    left: 50%;
  }

  .node:nth-child(10) {
    animation: node-animation 20s linear infinite;
    top: 5%;
    left: 25%;
  }

  .line {
    position: absolute;
    height: 2px;
    background-color: #00ff00;
    transform-origin: left center;
  }
  
  .line1 {
    top: 50%;
    left: 10%;
    width: 200%;
    transform: rotate(45deg);
  }
  
  .line2 {
    top: 50%;
    left: 40%;
    width: 200%;
    transform: rotate(-45deg);
  }
  
  .line3 {
    bottom: 10%;
    left: 0%;
    width: 200%;
    transform: rotate(-10deg);
  }
  
  .line4 {
    top: 52%;
    width: 200%;
    transform: rotate(-5deg);
  }

  .line5 {
    top: 0%;
    right: 30%;
    width: 200%;
    transform: rotate(-80deg);
  }

  .line6 {
    top: 50%;
    left: 70%;
    width: 200%;
    transform: rotate(80deg);
  }

  .line7 {
    top: 20%;
    left: 50%;
    width: 200%;
    transform: rotate(15deg);
  }

  .line8 {
    top: 10%;
    right: -10%;
    width: 300%;
    transform: rotate(5deg);
  }

  .line9 {
    top: 0%;
    left: 90%;
    width: 300%;
    transform: rotate(87deg);
  }


  .line10 {
    top: 0%;
    left: 10%;
    width: 300%;
    transform: rotate(93deg);
  }
  